import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { Story, StoryService } from 'src/app/api/core';

@Component({
  selector: 'app-story-hot-carousel',
  templateUrl: './story-hot-carousel.component.html',
})
export class StoryHotCarouselComponent implements OnInit {
  /**
   * hot stories
   */
  hotStories: Story[];

  constructor(
    protected notificationService: NotificationService,
    protected storyService: StoryService
  ) {}

  ngOnInit(): void {
    this.storyService
      .getHotStories()
      .pipe(
        first(),
      )
      .subscribe({
        next: (stories) => {
          this.hotStories = stories;
        },
      });
  }

}
