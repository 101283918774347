<div class="hot-carousel-container" *ngIf="hotStories?.length">
  <swiper-container
    *ngIf="hotStories?.length > 0"
    slides-per-view="auto"
    loop="false"
    css-mode="false"
    navigation="true"
    centered-slides="true"
    centered-slides-bounds="true"
    center-insufficient-slides="true"
    mousewheel="true"
    >
    <swiper-slide *ngFor="let story of hotStories">
      <app-story-card [story]="story" abstractLength="short"></app-story-card>
    </swiper-slide>
  </swiper-container>
</div>
