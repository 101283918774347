import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageComponent} from 'src/app/shared/page/page.component';
import {AuthGuard} from 'src/app/util/auth.guard';
import {ERoutePaths, EViewRoutes, EViewTitles,} from 'src/app/util/enum';
import {PermissionGuard} from 'src/app/util/permission.guard';
import {StoryResolver} from 'src/app/util/resolvers';
import {StoryCatalogueComponent} from './views/story-catalogue/story-catalogue.component';
import {StoryOverviewComponent} from './views/story-overview/story-overview.component';
import {EProtectedActions} from '../util/protected-actions';

/**
 * Story route definition.
 * All route definitions for Story related views.
 */
const storyRoutes: Routes = [
  {
    path: '',
    component: PageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ERoutePaths.all,
        component: StoryCatalogueComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.stories,
          viewRoute: EViewRoutes.stories,
          allowedRoles: [EProtectedActions.viewStories],
        },
      },
      {
        path: ERoutePaths.overview,
        component: StoryOverviewComponent,
        canActivate: [PermissionGuard],
        data: {
          title: EViewTitles.storyOverview,
          viewRoute: EViewRoutes.storyOverview,
          allowedRoles: [EProtectedActions.viewStories],
        },
        resolve: {
          story: StoryResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(storyRoutes)],
  exports: [RouterModule],
})
export class StoryRoutingModule {}
