<form [formGroup]="storyForm" class="modal-form" style="padding:4px;">
  <div class="row row-single">
    <mat-form-field>
      <mat-label>{{ 'storyName' | translate }}</mat-label>
      <input matInput disabled [value]="storyCreate.name" *ngIf="readOnly">
      <input matInput formControlName="name" type="text" *ngIf="!readOnly"/>
      <mat-error *ngIf="!readOnly && !storyForm.controls.name.valid">{{
        'inputTooLong'
          | translate: { maxLength: formValidators.textFieldMaxLength }
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="row row-double">
    <mat-form-field>
      <mat-label>{{ 'publicationType' | translate }}</mat-label>
      <input matInput disabled [value]="storyCreate.publicationType.name" *ngIf="readOnly">
      <mat-select formControlName="publicationType" *ngIf="!readOnly">
        <mat-option *ngFor="let publicationType of filteredPublicationTypes" [value]="publicationType">
          {{ publicationType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'hub' | translate }}</mat-label>
      <input matInput disabled [value]="storyCreate.hub.name" *ngIf="readOnly">
      <mat-select formControlName="hub" (selectionChange)="onHubChanged()" *ngIf="!readOnly">
        <mat-option *ngFor="let hub of hubs" [value]="hub">
          {{ hub.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
