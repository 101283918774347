<app-story-hot-carousel card-body></app-story-hot-carousel>

<div class="page-content-container">
  <div class="btn-container">
    <button
      mat-flat-button
      card-action
      class="add-story"
      color="primary"
      (click)="newStory()"
      *ngIf="protectedActions.createStory | protectedAction"
    >
      <app-config-icon
        iconIdentifier="open_in_new"
        class="color-white"
      ></app-config-icon>
      {{ 'addStory' | translate }}
    </button>
    <button
      mat-flat-button
      card-action
      class="add-story"
      color="primary"
      (click)="newTemplateStory()"
      *ngIf="protectedActions.createStory | protectedAction"
    >
      <app-config-icon
        iconIdentifier="foundation"
        class="color-white"
      ></app-config-icon>
      {{ 'createTemplateStory' | translate }}
    </button>
  </div>
  <app-grid
    [data]="data"
    tableId="story-catalogue"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    card-body
  ></app-grid>
</div>
