import {Component, OnDestroy} from '@angular/core';
import {CodeTableEntry, StoryService, StoryStatus} from 'src/app/api/core';
import {GlobalService} from 'src/app/services/global.service';
import {ModalService,} from 'src/app/services/modal.service';
import {ECodeTables, EModalType, EViewRoutes} from 'src/app/util/enum';
import {GridDataProvider} from 'src/app/shared/grid/data-source';
import {genIconColumn} from 'src/app/shared/grid/cell-renderers/icon.renderer';
import {TranslateService} from '@ngx-translate/core';
import {
  ApplyColumnStateParams, ColDef,
  GridOptions,
  GridReadyEvent,
  RowDoubleClickedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import {
  ToggleHotFilterComponent
} from 'src/app/shared/grid/custom-filters/toggle-hot-filter/toggle-hot-filter.component';
import {ModalData} from 'src/app/models/modal.model';
import {genCodeTableColumn, genDateColumn, genEnumColumn, genTextColumn,} from 'src/app/util/grid/grid-renderer.util';
import {I18n} from '../../../services/i18n.service';
import {Subscription} from 'rxjs';
import {EProtectedActions} from '../../../util/protected-actions';
import {genLinkColumn} from "../../../shared/grid/cell-renderers/link.renderer";
import {PermissionService} from "../../../services/permission.service";
import {CodeTableService} from "../../../services/code-table.service";
import {StoryCreateTemplateComponent} from "../../shared/story-create-template/story-create-template.component";
import {StoryCreateStandardComponent} from "../../shared/story-create-standard/story-create-standard.component";

/**
 * Component to show all stories on one page.
 */
@Component({
  selector: 'app-story-catalogue',
  templateUrl: './story-catalogue.component.html',
})
export class StoryCatalogueComponent implements OnDestroy {
  columnDefs: ColDef[] = [
    genIconColumn({
      field: 'hot',
      headerName: null,
      tooltip: this.translateService.instant('hotStory'),
      icon: (data: any) => (data.hot ? 'whatshot' : null),
      cellClass: ['hot'],
      customFilter: ToggleHotFilterComponent,
    }),
    genLinkColumn({
      field: 'name',
      headerName: I18n.getColName('storyName'),
      link: (data: any) => `${EViewRoutes.storyOverview}${data.id}`,
    }),
    genDateColumn({
      field: 'createdAt',
      headerName: I18n.getColName('creationDate'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.createdAt),
    }),
    genCodeTableColumn({
      field: 'useCase',
      headerName: I18n.getColName('useCase'),
      observable: this.codeTableService.getCodeTable(ECodeTables.useCase),
      filterHubs: () => this.hubs.map(h => h.ident),
    }),
    genTextColumn('author.fullname', I18n.getColName('author')),
    genDateColumn({
      field: 'validFrom',
      headerName: I18n.getColName('validFrom'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validFrom),
    }),
    genDateColumn({
      field: 'validTo',
      headerName: I18n.getColName('validTo'),
      dateFormatter: (params: ValueFormatterParams) =>
        this.globalService.dateToFrChLocale(params.data.validTo),
    }),
    genEnumColumn({
      field: 'status',
      values: [
        StoryStatus.DRAFT,
        StoryStatus.PUBLISHED,
        StoryStatus.CLOSED,
      ],
      headerName: I18n.getColName('status')
    }),
    {
      ...genTextColumn(
        'tags',
        I18n.getColName('tags'),
        (p) => p.data.tags.map((t) => t.name).join(', '),
        {
          customPath: 'tags.name',
        }
      ),
      sortable: false,
    },
    genCodeTableColumn({
      field: 'publicationType',
      headerName: I18n.getColName('publicationType'),
      observable: this.codeTableService.getCodeTable(ECodeTables.publicationType),
      filterHubs: () => this.hubs.map(h => h.ident),
    }),
    {
      ...genCodeTableColumn({
        field: 'hub',
        headerName: this.translateService.instant('hub'),
        filterValues: () => this.hubs,
        filterHubs: () => this.hubs.map(h => h.ident),
      }),
      hide: true,
      initialHide: true,
    },
  ];

  gridOptions: GridOptions = {
    rowHeight: 36,
    suppressContextMenu: true,
    suppressCellFocus: true,
    paginationAutoPageSize: true,
    onRowDoubleClicked: (event: RowDoubleClickedEvent) =>
      this.navigateTo(`${EViewRoutes.storyOverview}${event.data.id}`),
    onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
  };

  subscriptions: Subscription[] = [];
  hubs: CodeTableEntry[] = [];

  data: GridDataProvider = this.storiesService.getStories.bind(
    this.storiesService
  );

  constructor(
    protected storiesService: StoryService,
    protected globalService: GlobalService,
    protected modalService: ModalService,
    protected translateService: TranslateService,
    protected permissionService: PermissionService,
    protected codeTableService: CodeTableService,
  ) {
    this.subscriptions.push(
      permissionService.user$.subscribe(user => {
        this.hubs = [...user.hubs];
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get protectedActions() {
    return EProtectedActions;
  }

  /**
   * Function to open the modal to create a new story
   */
  newStory(): void {
    const modalData: ModalData = {
      type: EModalType.createStory,
      title: EModalType.createStory,
      data: null,
      submitBtn: null,
      cancelBtn: null,
      component: StoryCreateStandardComponent,
    };
    this.modalService.openDefaultDialog(modalData);
  }

  newTemplateStory() {
    const modalData: ModalData = {
      type: EModalType.createTemplateStory,
      title: EModalType.createTemplateStory,
      data: null,
      submitBtn: {
        label: this.translateService.instant('create'),
      },
      cancelBtn: {
        label: this.translateService.instant('cancel'),
      },
      component: StoryCreateTemplateComponent,
    };
    this.modalService.openConfirmationDialog(modalData);
  }

  /**
   * Routes user to clicked view
   * @param route Where the user should be routed to. Comes from HTML
   */
  navigateTo(route: string): void {
    this.globalService.navigate(route);
  }

  private onGridReady(event: GridReadyEvent) {
    this.subscriptions.push(I18n.getColumns(this.translateService, event.api));
    // set default sort if nothing is set
    if (event.api.getColumnState().findIndex((c) => c.sort) === -1) {
      const columnState: ApplyColumnStateParams = {
        state: [
          {
            colId: 'createdAt',
            sort: 'desc',
          },
        ],
      };
      event.api.applyColumnState(columnState);
    }
  }
}
