import {Component, OnDestroy, OnInit,} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {finalize, first,} from 'rxjs/operators';
import {StoryCreate, StoryService, StoryType, UserService,} from 'src/app/api/core';
import {ModalSubComponent} from 'src/app/models/modal.model';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {EViewRoutes,} from 'src/app/util/enum';
import {PermissionService} from '../../../services/permission.service';
import {ModalService} from "../../../services/modal.service";

@Component({
  selector: 'app-story-create-template',
  templateUrl: './story-create-template.component.html',
})
export class StoryCreateTemplateComponent
  implements OnInit, OnDestroy, ModalSubComponent {

  private storyCreate: StoryCreate = {
    name: '',
    publicationType: null,
    hub: null,
    hot: false,
    externalLinks: [],
    type: StoryType.TEMPLATE,
  };

  subscriptions: Subscription[] = [];

  constructor(
    protected storyService: StoryService,
    protected userService: UserService,
    protected globalService: GlobalService,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    protected permissionService: PermissionService,
    protected modalService: ModalService,
  ) {
  }

  ngOnInit(): void {
    // Initial check if form is valid
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = true;
  }

  onFormValidityChange(story: StoryCreate) {
    this.storyCreate = story;
    this.dialogRef.componentInstance.toolbarActionData.btnDisabled = story === undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  modalAction(_): void {
    this.dataService.updateLoading(true);
    this.storyService
      .createStory(this.storyCreate)
      .pipe(
        first(),
        finalize(() => {
          this.dataService.updateLoading(false);
          this.dialogRef.componentInstance.resetToolbarActionButtons();
        })
      )
      .subscribe({
        next: (story) => {
          this.dialogRef.close(true);
          this.notificationService.handleSuccess(
            this.translateService.instant('createStorySuccess')
          );
          this.globalService.navigate(
            `${EViewRoutes.storyOverview}${story.id}`
          );
        },
      });
  }

  protected readonly StoryType = StoryType;
}
