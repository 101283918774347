import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {FormBuilder, Validators,} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subscription} from 'rxjs';
import {CodeTableEntry, StoryCreate, StoryService, StoryType, UserService,} from 'src/app/api/core';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ECodeTables, EFormStatus, EFormValidators,} from 'src/app/util/enum';
import {PermissionService} from '../../../services/permission.service';
import {ModalService} from "../../../services/modal.service";

@Component({
  selector: 'app-story-details-main-form',
  templateUrl: './story-details-main-form.component.html',
})
export class StoryDetailsMainFormComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  storyForm = this.fb.group({
    name: [
      '',
      [
        Validators.required,
        Validators.maxLength(EFormValidators.textFieldMaxLength),
        Validators.pattern(EFormValidators.textFieldNotBlankPattern),
      ],
    ],
    publicationType: [
      null as CodeTableEntry,
      [Validators.required]
    ],
    hub: [
      null as CodeTableEntry,
      [Validators.required]
    ],
  });

  @Input()
  get readOnly() {
    return this._readOnly;
  }

  set readOnly(value: boolean) {
    this._readOnly = value;
    if (value) {
      this.storyForm.disable();
    } else {
      this.storyForm.enable();
    }
  }

  _readOnly = false;
  @Input()
  storyType: StoryType;

  @Input()
  get storyCreate() {
    return this._storyCreate;
  }

  set storyCreate(value: StoryCreate) {
    this._storyCreate = value;
  }

  _storyCreate: StoryCreate;
  // Store the last submitted data to avoid emitting the same data multiple times
  private lastSubmittedData: StoryCreate;
  @Output()
  dataValidityChanged = new EventEmitter<StoryCreate>();

  publicationTypes: CodeTableEntry[] = [];
  filteredPublicationTypes: CodeTableEntry[] = [];
  hubs: CodeTableEntry[] = [];

  constructor(
    private fb: FormBuilder,
    protected storyService: StoryService,
    protected userService: UserService,
    protected globalService: GlobalService,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected permissionService: PermissionService,
    protected modalService: ModalService,
  ) {
    this.initCodeTables();
  }

  ngOnInit(): void {
    // Mark publicationType as touched to show required error
    this.storyForm.controls.publicationType.markAsTouched({onlySelf: true});

    this.subscriptions.push(
      this.storyForm.statusChanges.subscribe(
        (status) => {
          if (status === EFormStatus.VALID) {
            this._storyCreate = {...this._storyCreate, ...this.storyForm.getRawValue(), externalLinks: []};
            // Only emit the data if it has changed
            if (!this.lastSubmittedData || JSON.stringify(this.lastSubmittedData) !== JSON.stringify(this._storyCreate)) {
              this.dataValidityChanged.emit(this._storyCreate);
              this.lastSubmittedData = this._storyCreate;
            }
          } else {
            this.dataValidityChanged.emit(undefined)
          }
        }
      )
    );
    if (!this._storyCreate) {
      this._storyCreate = {
        name: '',
        publicationType: null,
        hub: null,
        externalLinks: [],
        type: this.storyType,
        hot: false,
      };
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private initCodeTables() {
    combineLatest([
      this.codeTableService.getCodeTable(ECodeTables.publicationType)
    ]).subscribe(([publicationTypes]) => {
      // publication types
      this.publicationTypes = publicationTypes;
      // hubs
      this.hubs = this.globalService.getCurrentUserData().hubs.sort((a, b) => b.name.localeCompare(a.name));
      const hub = this.hubs[0];
      this.storyForm.patchValue({hub});
      this.filteredPublicationTypes = this.publicationTypes
        .filter(pt => pt.hubs.find(h => h.id === hub.id));
    });
  }

  /**
   * Ensure that the publication type is valid for the selected hub.
   */
  onHubChanged() {
    const values = this.storyForm.getRawValue();
    const hub = values.hub;
    const pubType = values.publicationType;
    if (hub) {
      this.filteredPublicationTypes = this.publicationTypes
        .filter(pt => pt.hubs.find(h => h.id === hub.id));
    }
    if (pubType && !this.filteredPublicationTypes.find(pt => pt.id === pubType?.id)) {
      this.storyForm.patchValue({publicationType: null});
    }
  }

  get formValidators() {
    return EFormValidators;
  }

}
