import {Component, OnDestroy, OnInit,} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {finalize, first,} from 'rxjs/operators';
import {
  CodeTableEntry,
  PublicationType,
  PublicationTypeService,
  Story,
  StoryCreate,
  StoryService,
  StoryType,
  UserService,
} from 'src/app/api/core';
import {CodeTableService} from 'src/app/services/code-table.service';
import {DataService} from 'src/app/services/data.service';
import {GlobalService} from 'src/app/services/global.service';
import {NotificationService} from 'src/app/services/notification.service';
import {ModalComponent} from 'src/app/shared/modal/modal.component';
import {ECodeTables, EViewRoutes,} from 'src/app/util/enum';
import {PermissionService} from '../../../services/permission.service';
import {ModalService} from "../../../services/modal.service";
import {DatePipe} from "@angular/common";

type WizardPage = 'main-data' | 'details';

@Component({
  selector: 'app-story-create-standard',
  templateUrl: './story-create-standard.component.html',
})
export class StoryCreateStandardComponent
  implements OnInit, OnDestroy {

  currentPage: WizardPage = 'main-data';

  storyCreate: StoryCreate;
  story: Story;
  isValid = false;

  subscriptions: Subscription[] = [];
  mainDataReadOnly = false;
  useCases: CodeTableEntry[] = [];

  constructor(
    protected storyService: StoryService,
    protected userService: UserService,
    protected globalService: GlobalService,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected translateService: TranslateService,
    protected codeTableService: CodeTableService,
    protected dialogRef: MatDialogRef<ModalComponent>,
    protected permissionService: PermissionService,
    protected publicationTypeService: PublicationTypeService,
    private datePipe: DatePipe,
    protected modalService: ModalService,
  ) {
  }

  ngOnInit(): void {
    this.codeTableService.getCodeTable(ECodeTables.useCase).subscribe((useCases) => {
      this.useCases = useCases;
    });
  }

  /**
   * Executed after the first form validity is updated.
   * @param story
   */
  onFormValidityChange(story: StoryCreate) {
    // only pre-populate if the story is not already set
    if (!this.story) {
      this.storyCreate = story;
    }
    if (this.storyCreate && !this.story) {
      // only pre-populate after the first form validity change
      this.publicationTypeService.getPublicationType(this.storyCreate.publicationType.id)
        .subscribe((publicationType) => {
          const populatedData = this.prePopulateStoryData(publicationType);
          this.story = {
            ...this.storyCreate,
            ...populatedData,
            id: 0,
            ident: '',
            tags: [],
            deletable: false,
            editable: false,
            autoSyncContent: false,
            autoSyncAllowedUsers: false,
            autoSyncBuyProducts: false,
            autoSyncSellProducts: false,
            status: 'DRAFT',
            contents: [],
            filter: 0,
          };
        });
    }
  }

  private prePopulateStoryData(publicationType: PublicationType): any {
    const result: any = {};
    const toDateStr = (date: Date) => this.datePipe.transform(date, 'yyyy-MM-dd');
    result.useCase = (
      publicationType.advised ?
        this.useCases.find(u => u.ident === 'advised')
        : this.useCases.find(u => u.ident === 'unadvised')
    );
    const from = new Date();
    switch (publicationType.timeFrame) {
      case 'NUMBER_OF_DAYS':
        let to = new Date();
        to.setDate(from.getDate() + publicationType.timeValue);
        result.validFrom = toDateStr(from);
        result.validTo = toDateStr(to);
        break;
      case 'NUMBER_OF_WEEKS':
        let toWeek = new Date();
        toWeek.setDate(from.getDate() + publicationType.timeValue * 7);
        result.validFrom = toDateStr(from);
        result.validTo = toDateStr(toWeek);
        break;
      case 'NUMBER_OF_MONTHS':
        let toMonth = new Date();
        toMonth.setMonth(from.getMonth() + publicationType.timeValue);
        result.validFrom = toDateStr(from);
        result.validTo = toDateStr(toMonth);
        break;
      case 'START_OF_NEXT_MONTH':
        let startNextMonth = new Date(from.getFullYear(), from.getMonth() + 1, 1);
        result.validFrom = toDateStr(startNextMonth);
        break;
      case 'END_OF_NEXT_MONTH':
        let endNextMonth = new Date(from.getFullYear(), from.getMonth() + 2, 0);
        result.validFrom = toDateStr(from);
        result.validTo = toDateStr(endNextMonth);
        break;
      case 'END_OF_CURRENT_MONTH':
        let endCurrentMonth = new Date(from.getFullYear(), from.getMonth() + 1, 0);
        result.validFrom = toDateStr(from);
        result.validTo = toDateStr(endCurrentMonth);
        break;
    }
    return result;
  }

  onDetailsFormValidityChange(story: Story) {
    this.story = story;
    this.isValid = story !== undefined;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  submit(): void {
    if (!this.isValid) {
      return;
    }
    this.dataService.updateLoading(true);
    this.storyService
      .createStory(this.story)
      .pipe(
        first(),
        finalize(() => {
          this.dataService.updateLoading(false);
        })
      )
      .subscribe({
        next: (story) => {
          this.dialogRef.close(true);
          this.notificationService.handleSuccess(
            this.translateService.instant('createStorySuccess')
          );
          this.globalService.navigate(
            `${EViewRoutes.storyOverview}${story.id}`
          );
        },
      });
  }

  protected readonly StoryType = StoryType;

  onNext() {
    this.currentPage = 'details';
    if (this.storyCreate !== undefined) {
      this.mainDataReadOnly = true;
    }
  }

  onPrev() {
    this.currentPage = 'main-data';
  }
}
