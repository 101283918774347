<div class="container">
  <app-story-details-main-form
    *ngIf="currentPage === 'main-data'"
    [storyType]="StoryType.STANDARD" [storyCreate]="storyCreate" (dataValidityChanged)="onFormValidityChange($event)"
    [readOnly]="mainDataReadOnly"
  >
  </app-story-details-main-form>
  <div *ngIf="currentPage === 'details'">
    <app-details-form [story]="story" (dataValidityChanged)="onDetailsFormValidityChange($event)" [readOnlyMainData]="true"></app-details-form>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button *ngIf="currentPage === 'main-data'" mat-raised-button
          [matTooltip]="'storyCreateNextStepWarning' | translate"
          [disabled]="!story"
          (click)="onNext()">{{ 'next' | translate }}
  </button>
  <button *ngIf="currentPage === 'details'" mat-raised-button
          (click)="onPrev()">{{ 'back' | translate }}
  </button>
  <button *ngIf="currentPage === 'details'" mat-raised-button
          color="primary"
          [disabled]="!isValid"
          (click)="submit()">{{ 'create' | translate }}
  </button>
</mat-dialog-actions>
