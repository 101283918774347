import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoryCatalogueComponent } from './views/story-catalogue/story-catalogue.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StoryRoutingModule } from './story-routing.module';
import { StoryOverviewComponent } from './views/story-overview/story-overview.component';
import { StoryOverviewDetailsComponent } from './views/story-overview/story-overview-details/story-overview-details.component';
import { StoryDetailsFormComponent } from './shared/story-details-form/story-details-form.component';
import { StoryHotCarouselComponent } from './shared/story-hot-carousel/story-hot-carousel.component';
import { StoryOverviewToolbarComponent } from './views/story-overview/story-overview-toolbar/story-overview-toolbar.component';
import {PortfolioModule} from '../portfolio/portfolio.module';
import {StoryDetailsImageComponent} from "./shared/story-details-image/story-details-image.component";
import {IntermediaryModule} from "../intermediary/intermediary.module";
import {StoryCreateTemplateComponent} from "./shared/story-create-template/story-create-template.component";
import {StoryDetailsMainFormComponent} from "./shared/story-details-main-form/story-details-main-form.component";
import {StoryCreateStandardComponent} from "./shared/story-create-standard/story-create-standard.component";
import {StoryDetailsFormDialogComponent} from "./shared/story-details-form-dialog/story-details-form-dialog.component";

/**
 * Story Module.
 * Contains all components related to Story.
 */
@NgModule({
  declarations: [
    StoryCatalogueComponent,
    StoryCreateStandardComponent,
    StoryCreateTemplateComponent,
    StoryDetailsFormComponent,
    StoryDetailsFormDialogComponent,
    StoryDetailsImageComponent,
    StoryDetailsMainFormComponent,
    StoryHotCarouselComponent,
    StoryOverviewComponent,
    StoryOverviewDetailsComponent,
    StoryOverviewToolbarComponent,
  ],
    imports: [CommonModule, SharedModule, StoryRoutingModule, PortfolioModule, IntermediaryModule],
  exports: [
    StoryDetailsImageComponent
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StoryModule {}
